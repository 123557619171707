import React, { useEffect, useMemo, useRef, useState } from "react"
import { testimonials } from "../../utilities/testimonials"
import "./testimonialsslidervs.scss"
import Image from "../../components/image-component/image-new"
import { Splide, SplideSlide } from "@splidejs/react-splide"
import { PlayIcon2, QuoteIcon2 } from "../../microcomponents/icons"
import Modal from "../../components/modal/modal"
import YouTube from "react-youtube"
import ReactHtmlParser from "react-html-parser"

const stripColors = [
  "rgba(24, 84, 249, 1)",
  "rgba(12, 63, 203, 1)",
  "rgba(4, 40, 137, 1)",
  "rgba(5, 26, 86, 1)",
]
export default function TestimonialsSliderV2() {
  const [activeSlide, setActiveSlide] = useState("okta")
  const splideRef = useRef()
  const testimonialsSet = useMemo(() => {
    return testimonials
      ?.filter(item =>
        ["workday", "okta", "record360", "podium", "progress"].includes(item.id)
      )
      .sort()
  }, [testimonials])

  useEffect(() => {
    if (splideRef.current) {
      splideRef.current?.splide.on("moved", (newIndex, oldIndex, destIndex) => {
        setActiveSlide(testimonialsSet[newIndex].id)
      })
    }
  }, [splideRef])

  const TSlideNav = ({ testimonialsSet, className }) => (
    <div className={className}>
      {testimonialsSet
        ?.filter(item => item.id !== activeSlide)
        ?.map((item, index) => (
          <div
            style={
              activeSlide === item.id
                ? {
                    display: "none",
                  }
                : {
                    backgroundColor: stripColors[index],
                  }
            }
            key={index}
            onClick={e => {
              const getIndex = splideRef.current.slides.findIndex(
                slide => slide.getAttribute("data-splide-item") === `${item.id}`
              )
              splideRef.current.go(getIndex)
              // setActiveSlide(item.id)
            }}
          >
            <div>{item?.tSlider?.logo}</div>
          </div>
        ))}
    </div>
  )

  return (
    <div className="full-vw-section-wrapper testimonial-continous-slider">
      <div className="t-slider-wrapper">
        <Splide
          ref={splideRef}
          options={{
            arrows: false,
            pagination: false,
            type: "slide",
            // autoplay: true,
            // interval: 3000,
            // focus: "center",
            perPage: 1,
            perMove: 1,
            width: "100%",
            breakpoints: {
              1360: {
                // arrows: true,
                // autoWidth: true,
                autoHeight: true,
              },
            },
          }}
          aria-label="Testimonials Slide"
        >
          {testimonialsSet.map((item, index) => (
            <SplideSlide
              key={index}
              data-splide-item={`${item.id}`}
              className={`slide-item-v2 hero-slider-item`}
            >
              <div className="t-slider-item">
                <div>
                  <div className="t-slide-people pd-112-top pd-112-bottom">
                    <Image
                      sourceFolder={"testimonialsnew"}
                      className="logo hidedesktop hidetablet hideipad"
                      imgName={item.logo}
                      alt={item.alt}
                    />
                    <p>
                      <QuoteIcon2 />
                      {ReactHtmlParser(item?.testimonialDescription)}
                    </p>
                    <div className="t-slide-desig-wrapper">
                      {/* {console.log(item)} */}
                      <div className="t-slide-desig">
                        <div>
                          {item.tSlider?.personImage && (
                            <Image
                              sourceFolder={
                                item?.tSlider?.folderName ||
                                "abtestingagencydesign-donversion"
                              }
                              className=""
                              cloudinaryPath="https://res.cloudinary.com/spiralyze/image/upload/f_auto/spzinternal-website"
                              imgName={item.tSlider?.personImage}
                              fallBackImage={item.tSlider?.personImage}
                              alt={item.placeholderAlt || "Customer logos"}
                            />
                          )}
                        </div>

                        <div>
                          <h4>{item?.personName}</h4>
                          <p>{item?.personDesignation}</p>
                        </div>
                      </div>
                      <Image
                        sourceFolder={"testimonialsnew"}
                        className="logo hidemobile"
                        imgName={item.logo}
                        alt={item.alt}
                      />
                    </div>
                  </div>
                </div>

                <div>
                  <div className="video-wrapper">
                    <Modal
                      modalButton={
                        <span
                          className="hover-btn"
                          style={{ cursor: "pointer" }}
                        >
                          <div className="playshowonhover">
                            <PlayIcon2 />
                          </div>
                        </span>
                      }
                      modalContent={
                        <div className="iframe-wrap">
                          <YouTube videoId={item.videoID} />
                        </div>
                      }
                    />
                  </div>
                  <Image
                    sourceFolder={
                      item?.tSlider?.folderName ||
                      "abtestingagencydesign-donversion"
                    }
                    className=""
                    cloudinaryPath="https://res.cloudinary.com/spiralyze/image/upload/f_auto/spzinternal-website/"
                    imgName={item.tSlider?.image}
                    fallBackImage={item.tSlider?.image}
                    alt={item.placeholderAlt || "Customer logos"}
                  />
                  <TSlideNav
                    testimonialsSet={testimonialsSet}
                    className="t-slider-nav-wrapper hidedesktop"
                  />
                </div>
              </div>
            </SplideSlide>
          ))}
        </Splide>
      </div>
      <TSlideNav
        testimonialsSet={testimonialsSet}
        className="t-slider-nav-wrapper hideipad"
      />
    </div>
  )
}
