import React from "react"
import HeaderV2 from "../headerv2/headerv2"
import Hero from "../../microcomponents/hero/hero"
import Image from "../../components/image-component/image-new"
import LeftRightImageText from "../../microcomponents/leftrightimagetext/leftrightimagetext"
import Button from "../../microcomponents/button/button"
import {
  ArrowIcon,
  CompareSliderIcon,
  ControlIcon,
  VariantIcon,
} from "../../microcomponents/icons"
import ReactCompareImage from "react-compare-image"
import ReactHtmlParser from "react-html-parser"
import TopPredictions from "../../microcomponents/toppredictions/toppredictions"
import { faqs, faqsLandingPage } from "../../utilities/faqs"
import FrequentlyAskedQuestions from "../frequentlyaskedquestions/frequentlyaskedquestions"
import { Splide, SplideSlide } from "@splidejs/react-splide"
import TestimonialsSliderV2 from "../testimonialsslidev2/testimonialssliderv2"
import ClientLogosLp from "../../microcomponents/LP/clientlogoslp"

export default function LandingPageOptimizationAgencyV2() {
  const SliderComponent = () => {
    const leftSliderData = [
      {
        controlImage:
          "//res.cloudinary.com/spiralyze/image/upload/f_auto/spzinternal-website/spiralyze2024website/_comparison_slidernoyesno_3.webp",
        variantImage:
          "//res.cloudinary.com/spiralyze/image/upload/f_auto/spzinternal-website/spiralyze2024website/_comparison_slidernoyesno_2.webp",
        comparisonCaption:
          "<strong>Sched.com</strong> tested moving its Contact Sales form to the left. It won.",
      },
    ]
    return (
      <>
        {leftSliderData.map((item, index) => {
          return (
            <div className="slide-item-v2" key={index}>
              <div className="react-image-compare-slider showlabels">
                <ReactCompareImage
                  handle={<CompareSliderIcon />}
                  handleSize={64}
                  sliderLineColor={"#1854F9"}
                  sliderLineWidth={1}
                  sliderPositionPercentage={0.73}
                  leftImage={item.controlImage}
                  rightImage={item.variantImage}
                />
              </div>
              <div className="comparison-caption">
                {ReactHtmlParser(item.comparisonCaption)}
              </div>
            </div>
          )
        })}
      </>
    )
  }

  const HeroSliderComponent = () => {
    const leftSliderData = [
      {
        variantImage:
          "//res.cloudinary.com/spiralyze/image/upload/f_auto/spzinternal-website/abtestingagencydesign-donversion/1_before_hero_img_1440.webp",
        controlImage:
          "//res.cloudinary.com/spiralyze/image/upload/v1734079428/spzinternal-website/abtestingagencydesign-donversion/afterimage1.webp",
        uplift: "30%",
        daysToWin: "25",
        testLaunched: "10",
      },
      {
        variantImage:
          "//res.cloudinary.com/spiralyze/image/upload/f_auto/spzinternal-website/abtestingagencydesign-donversion/2_before_hero_img_1441.webp",
        controlImage:
          "//res.cloudinary.com/spiralyze/image/upload/f_auto/spzinternal-website/abtestingagencydesign-donversion/9020__1201.webp",
        uplift: "29%",
        daysToWin: "32",
        testLaunched: "12",
      },
      {
        variantImage:
          "//res.cloudinary.com/spiralyze/image/upload/f_auto/spzinternal-website/abtestingagencydesign-donversion/3_before_hero_img_1441.webp",
        controlImage:
          "//res.cloudinary.com/spiralyze/image/upload/f_auto/spzinternal-website/abtestingagencydesign-donversion/3_after_hero_img_1442.webp",
        uplift: "32%",
        daysToWin: "24",
        testLaunched: "9",
      },
    ]

    return (
      <>
        {/* <TopPredictions
          version="v2 landing-page-spz"
          subheading=""
          heading=""
          wrapperClass="hero-form"
          icon={null}
          placeholder="Enter your business email"
        /> */}
        <Splide
          options={{
            arrows: true,
            pagination: false,
            type: "slide",
            focus: "center",
            perPage: 1,
            perMove: 1,
            width: "100%",
            drag: false,
          }}
          aria-label="React Splide Example"
          className=""
        >
          {leftSliderData.map((item, index) => (
            <SplideSlide
              key={index}
              className={`slide-item-v2 pd-40-top pd-40-bottom hero-slider-item lp`}
            >
              <div
                className="react-image-compare-slider"
                style={{
                  display: "flex",
                }}
              >
                <span className="varianticon">
                  <VariantIcon />
                </span>
                <img src="//res.cloudinary.com/spiralyze/image/upload/f_auto/spzinternal-website/abtestingagencydesign-donversion/address_bar.png" />
                <span className="controlicon">
                  <ControlIcon />
                </span>
              </div>
              <div className="react-image-compare-slider">
                <div className="slide-box up-box">
                  <div className="h3">
                    <span>{item.uplift}</span>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                    >
                      <path
                        d="M21 8.414L13.4 16.014L9.39998 12.014L3.40698 18.007L1.99298 16.593L9.39998 9.186L13.4 13.186L19.586 7L21 8.414Z"
                        fill="#9C0DCB"
                      />
                      <path
                        d="M15.636 8V6H22V12.354H20V8H15.636Z"
                        fill="#D517EB"
                      />
                    </svg>
                  </div>
                  <span>
                    average lift<sup>*</sup>{" "}
                  </span>
                  <span className="subhead">
                    <sup>*</sup>-in first 90 days.
                  </span>
                </div>
                <ReactCompareImage
                  handle={<CompareSliderIcon />}
                  handleSize={64}
                  sliderLineColor={"#1854F9"}
                  sliderLineWidth={1}
                  sliderPositionPercentage={0.5}
                  leftImage={item.controlImage}
                  rightImage={item.variantImage}
                />

                <div className="slide-box days-box">
                  <div className="h3">
                    <span>{item.daysToWin}</span>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                    >
                      <path
                        d="M16.75 3.56V2C16.75 1.59 16.41 1.25 16 1.25C15.59 1.25 15.25 1.59 15.25 2V3.5H8.75V2C8.75 1.59 8.41 1.25 8 1.25C7.59 1.25 7.25 1.59 7.25 2V3.56C4.55 3.81 3.24 5.42 3.04 7.81C3.02 8.1 3.26 8.34 3.54 8.34H20.46C20.75 8.34 20.99 8.09 20.96 7.81C20.76 5.42 19.45 3.81 16.75 3.56Z"
                        fill="#1854F9"
                      />
                      <path
                        opacity="0.4"
                        d="M21 10.8401V12.5801C21 13.1901 20.46 13.6601 19.86 13.5601C19.58 13.5201 19.29 13.4901 19 13.4901C15.97 13.4901 13.5 15.9601 13.5 18.9901C13.5 19.4501 13.68 20.0901 13.87 20.6701C14.09 21.3201 13.61 21.9901 12.92 21.9901H8C4.5 21.9901 3 19.9901 3 16.9901V10.8301C3 10.2801 3.45 9.83008 4 9.83008H20C20.55 9.84008 21 10.2901 21 10.8401Z"
                        fill="#1854F9"
                      />
                      <path
                        d="M19 15C16.79 15 15 16.79 15 19C15 19.75 15.21 20.46 15.58 21.06C16.27 22.22 17.54 23 19 23C20.46 23 21.73 22.22 22.42 21.06C22.79 20.46 23 19.75 23 19C23 16.79 21.21 15 19 15ZM21.07 18.57L18.94 20.54C18.8 20.67 18.61 20.74 18.43 20.74C18.24 20.74 18.05 20.67 17.9 20.52L16.91 19.53C16.62 19.24 16.62 18.76 16.91 18.47C17.2 18.18 17.68 18.18 17.97 18.47L18.45 18.95L20.05 17.47C20.35 17.19 20.83 17.21 21.11 17.51C21.39 17.81 21.37 18.28 21.07 18.57Z"
                        fill="#1854F9"
                      />
                      <path
                        d="M8.5 14.9999C8.24 14.9999 7.98 14.8899 7.79 14.7099C7.61 14.5199 7.5 14.2599 7.5 13.9999C7.5 13.7399 7.61 13.4799 7.79 13.2899C8.02 13.0599 8.37 12.9499 8.7 13.0199C8.76 13.0299 8.82 13.0499 8.88 13.0799C8.94 13.0999 9 13.1299 9.06 13.1699C9.11 13.2099 9.16 13.2499 9.21 13.2899C9.39 13.4799 9.5 13.7399 9.5 13.9999C9.5 14.2599 9.39 14.5199 9.21 14.7099C9.16 14.7499 9.11 14.7899 9.06 14.8299C9 14.8699 8.94 14.8999 8.88 14.9199C8.82 14.9499 8.76 14.9699 8.7 14.9799C8.63 14.9899 8.56 14.9999 8.5 14.9999Z"
                        fill="#1854F9"
                      />
                      <path
                        d="M12 14.9999C11.74 14.9999 11.48 14.8899 11.29 14.7099C11.11 14.5199 11 14.2599 11 13.9999C11 13.7399 11.11 13.48 11.29 13.29C11.67 12.92 12.34 12.92 12.71 13.29C12.89 13.48 13 13.7399 13 13.9999C13 14.2599 12.89 14.5199 12.71 14.7099C12.52 14.8899 12.26 14.9999 12 14.9999Z"
                        fill="#1854F9"
                      />
                      <path
                        d="M8.5 18.4999C8.24 18.4999 7.98 18.3899 7.79 18.2099C7.61 18.0199 7.5 17.7599 7.5 17.4999C7.5 17.2399 7.61 16.9799 7.79 16.7899C7.89 16.6999 7.99 16.6299 8.12 16.5799C8.49 16.4199 8.93 16.5099 9.21 16.7899C9.39 16.9799 9.5 17.2399 9.5 17.4999C9.5 17.7599 9.39 18.0199 9.21 18.2099C9.02 18.3899 8.76 18.4999 8.5 18.4999Z"
                        fill="#1854F9"
                      />
                    </svg>
                  </div>
                  <span>
                    days to <br /> first win
                  </span>
                </div>
                <div className="slide-box tests-box">
                  <div className="h3">
                    <span>{item.testLaunched}</span>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="24"
                      height="22"
                      viewBox="0 0 24 22"
                      fill="none"
                    >
                      <path
                        d="M1.19676 21.7155L3.2112 20.4335C3.30277 20.3878 3.44012 20.4335 3.44012 20.5709L3.34855 21.3034C3.34855 21.4408 3.44012 21.5323 3.57747 21.4865L6.91961 20.4793C8.33888 20.0673 9.30032 18.7396 9.30032 17.2745C9.30032 15.4432 7.78949 13.9324 5.95817 13.9324H5.91239C4.44734 13.9324 3.16542 14.8938 2.75338 16.2673L0.967846 21.4865C0.87628 21.6697 1.05941 21.807 1.19676 21.7155Z"
                        fill="#FFBD33"
                      />
                      <path
                        d="M10.9027 7.61427L7.83525 7.52271C7.28586 7.52271 6.78225 7.70584 6.3702 8.02632L2.79914 11.1395L6.87381 12.3757M15.6641 12.3757L15.7557 15.4431C15.7557 15.9925 15.5726 16.4961 15.2521 16.9082L12.1388 20.4792L10.9485 16.4961"
                        fill="#E93E3E"
                      />
                      <path
                        d="M10.9485 16.4962C12.0931 16.0841 14.9774 14.9395 17.4954 12.3757C23.493 6.42393 23.0352 0.243247 23.0352 0.243247C23.0352 0.243247 16.9003 -0.214582 10.9027 5.73719C7.65213 8.98777 7.1943 11.1853 6.78226 12.2841L10.9485 16.4962Z"
                        fill="#F09696"
                      />
                      <path
                        d="M16.4424 8.8045C17.5044 8.8045 18.3653 7.9436 18.3653 6.88162C18.3653 5.81964 17.5044 4.95874 16.4424 4.95874C15.3805 4.95874 14.5196 5.81964 14.5196 6.88162C14.5196 7.9436 15.3805 8.8045 16.4424 8.8045Z"
                        fill="white"
                      />
                      <path
                        d="M7.83525 16.1298L7.14851 15.443L11.6352 10.2696C12.0015 9.90331 12.6424 9.90331 13.0087 10.2696C13.375 10.6358 13.375 11.2768 13.0087 11.6431L7.83525 16.1298Z"
                        fill="#E93E3E"
                      />
                    </svg>
                  </div>
                  <span>
                    tests
                    <br /> launched
                  </span>
                </div>
              </div>
            </SplideSlide>
          ))}
        </Splide>
      </>
    )
  }

  return (
    <div>
      <HeaderV2
        version="v2 landing-page-spz"
        // ctaIcon={<GreaterThanIcon />}
      />
      <Hero
        version="v2"
        className="landing-page-hero"
        breadcrumb={[]}
        subHeading={`LANDING PAGE OPTIMIZATION AGENCY FOR B2B SAAS`}
        heading="Boost <br class='hidedesktop hidetablet hideipad'/> Conversions <span class='white-text'>by 30%<br class='hidemobile'/> in 90 Days.</span>  <span class='yellow-text'> Guaranteed.</span>"
        description="<p>Landing page optimization based on insights from thousands of experiments. Dedicated CRO specialists handle everything from ideation to design and dev. Pay only when we lift your conversions.</p>"
        tags={[
          "Prediction Engine",
          "Landing Page Optimization Team",
          "Expert Insights",
          "No Results, No Payment",
        ]}
        customImageComponent={
          <>
            <HeroSliderComponent />
          </>
        }
        heroCtaIcon={null}
      />
      {/* <div className="lp-bg">
        <Image
          sourceFolder="abtestingagencydesign-donversion"
          imgName="logos.webp"
          alt="Hero Animation"
          cloudinaryPath="https://res.cloudinary.com/spiralyze/image/upload/f_auto/spzinternal-website"
        />
      </div> */}

      <ClientLogosLp
        version="v2 blue-bg pd-40-bottom"
        sourceFolder="lp"
        // cloudinaryPath="https://res.cloudinary.com/spiralyze/image/upload/f_auto/spzinternal-website"
        logos={[
          {
            name: "Logo.svg",
            alt: "Gusto",
            pictureTagIndexes: [],
          },
          {
            name: "Logo-1.svg",
            alt: "Podium",
            pictureTagIndexes: [],
          },
          {
            name: "Logo-2.svg",
            alt: "Arctic Wolf",
            pictureTagIndexes: [],
          },
          {
            name: "Logo-3.svg",
            alt: "Unbounce",
            pictureTagIndexes: [],
          },
          { name: "Logo-5.svg", alt: "Crowd Strike", pictureTagIndexes: [] },
          {
            name: "Logo-4.svg",
            alt: "Filevine",
            pictureTagIndexes: [],
          },
          {
            name: "Logo-6.svg",
            alt: "EcoOnline",
            pictureTagIndexes: [],
          },
          {
            name: "Logo-7.svg",
            alt: "Velocity Global",
            pictureTagIndexes: [],
          },
        ]}
      />

      <LeftRightImageText
        mainHeading="Run proven tests based on 78,000 winners."
        subHeading="Prediction Engine"
        sectionDescription="<p>Spiralyze collects A/B test data from 78,000 landing pages to find top performers. Our machine learning predicts what will win on your site.</p><p>Get 3X higher win rates than other landing page optimization companies.</p>"
        imageFolder="how_it_works"
        sectionImage="howitworksimage1.webp"
        reverse={true}
        innerClasses="pd-112 landing-page-spz"
        id="predictionengine"
        imageComponent={
          <>
            <SliderComponent />
          </>
        }
      >
        <Button
          version={"v2"}
          href="/get-demo/"
          // icon={<GreaterThanIcon />}
          type={``}
        >
          Get a Demo
        </Button>
      </LeftRightImageText>

      <LeftRightImageText
        mainHeading="Get done-for-you testing with a team of CRO experts."
        subHeading="Full Service Optimization Agency"
        sectionDescription="<p>Run 3x more A/B tests on your landing pages. Our team of CRO specialists handle everything for you. Design, copy, dev/QA, analytics, and project management. First test live in just 2 weeks.</p>"
        innerClasses="reverse pd-112 landing-page-spz"
        id="landingpageoptimizationteam"
        wrapperClasses="gray-bg"
        imageComponent={
          <>
            <Image
              sourceFolder={"spiralyze2024website"}
              lazyLoad={"true"}
              fallBackImage={`filevine_ui_screen_1440.webp`}
              imgName={`filevine_ui_screen_1440.webp`}
              cloudinaryPath="https://res.cloudinary.com/spiralyze/image/upload/f_auto/spzinternal-website"
            />
            <p>
              <strong>Filevine.com</strong> tested moving its dashboard
              interface to hero section and <br className="hidemobile" /> adding
              Features tiles below main headline.
            </p>
          </>
        }
      >
        <Button
          version={"v2"}
          href="/full-service-team/"
          icon={<ArrowIcon />}
          type={`cta`}
        >
          Meet Your CRO Experts
        </Button>
      </LeftRightImageText>

      <LeftRightImageText
        mainHeading="See what keeps users from converting."
        subHeading="Research Insights"
        sectionDescription="<p>Get in-depth customer insights from analytics, exit polling, session recordings, heatmaps, review mining, message testing, and more. </p><p>Custom research adds a layer of qualitative data to the insights from our prediction engine.</p>"
        imageFolder="abtestingagencydesign-donversion"
        sectionImage="cybrari_image_1441.webp"
        reverse={true}
        innerClasses="pd-112 landing-page-spz"
        id="expertinsights"
        imageComponent={
          <>
            <Image
              sourceFolder={"spiralyze2024website"}
              lazyLoad={"true"}
              fallBackImage={`cybrari_image_1440.webp`}
              imgName={`cybrari_image_1440.webp`}
              cloudinaryPath="https://res.cloudinary.com/spiralyze/image/upload/f_auto/spzinternal-website"
            />
            <p>
              <strong>Cybrary.com</strong> tested its Pricing plans page.
            </p>
          </>
        }
      >
        <Button
          version={"v2"}
          href="/get-demo/"
          // icon={<GreaterThanIcon />}
          type={``}
        >
          Get a Demo
        </Button>
      </LeftRightImageText>
      <LeftRightImageText
        mainHeading="30% growth in 90 days. Pay only if we deliver."
        subHeading="No Results, No Payment"
        sectionDescription="<p>10 A/B tests, 3 wins, 30% lift in your first 90 days. We don't get paid until after you get the promised lift on your landing page. You’re the sole judge.</p>"
        innerClasses="reverse pd-112 landing-page-spz"
        id="noresults,nopayment"
        wrapperClasses="gray-bg"
        imageComponent={
          <>
            <Image
              sourceFolder={"spiralyze2024website"}
              lazyLoad={"true"}
              fallBackImage={`expensifycom.webp`}
              imgName={`expensifycom.webp`}
              cloudinaryPath="https://res.cloudinary.com/spiralyze/image/upload/f_auto/spzinternal-website"
            />
            <p>
              <strong>Expensify.com</strong> tested placing double CTA on its
              homepage.
            </p>
          </>
        }
      >
        <Button
          version={"v2"}
          href="/get-demo/"
          // icon={<GreaterThanIcon />}
          type={``}
        >
          Get a Demo
        </Button>
      </LeftRightImageText>
      <TopPredictions
        version="v2 landing-page-spz"
        subheading="See the top 3 CRO recommendations from our prediction engine for<br class='hidetablet hideipad hidemobile'/> your landing page when you book a demo."
        heading="Get the <span class='yellow-text'>Top 3</span> <span class='white-text'>predictions</span> for your site"
        icon={null}
        placeholder="Enter your business email"
      />
      <TestimonialsSliderV2 />
      <FrequentlyAskedQuestions
        version="v2 landing-page-spz blue-bg"
        faqs={faqsLandingPage}
      />
    </div>
  )
}
