import * as React from "react"
import Layout from "../components/layout2023"
import FooterV2 from "../componentsv2/footerv2/footerv2"
import LandingPageOptimizationAgencyV2 from "../componentsv2/landingpageoptimizationagencyv2/landingpageoptimizationagencyv2"
import SEO from "../components/seo"

const LandingPageOptimizationAgency = () => (
  <Layout nav={"hidden"} parentClass={`newtheme2024 landing-page-spz`}>
    <SEO
      title="Landing Page Optimization Agency: Boost Conversions 30%
        "
      showDefaultTitle={false}
      description="Landing page optimization agency what helps you convert 30% more in 90 days with proven strategies. Full-service design and dev for faster results."
    />
    <LandingPageOptimizationAgencyV2 />
    <FooterV2 version="v2 landing-page-spz lp-bg" />
  </Layout>
)

export default LandingPageOptimizationAgency
